import React from "react"
// import { Link } from "gatsby"
// import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../seo/seo"

import WhatWeOffer from "../components/index/whatWeOffer/whatWeOffer"

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  
  return (
    <Layout>
      <SEO title={`Services | ${data.site.siteMetadata.title}`} />
      <div className="page">
        <WhatWeOffer />
      </div>
    </Layout>
  )
}

export default Services
