import React from 'react';
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import OfferCard from "../../offerCard"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 90,
    paddingBottom: 50,
  },
}));

const WhatWeOffer = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query WhatWeOfferQuery {
      allContentfulWhatWeOffer (sort: {order: ASC, fields: offerId}) {
        edges {
          node {
            offerId
            offerTitle
            offerDescription {
              offerDescription
            }
            slug
            categories {
              serviceName
              serviceSlug
              serviceDescription {
                serviceDescription
              }              
              serviceImage {
                fluid (maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
                description
              }
              serviceImages {
                title
              }
            }
          }
        }
      }
    }
  `);

  return (
    data.allContentfulWhatWeOffer.edges.map(item => {
      const {node: {
        offerId,
        offerTitle,
        slug,
        offerDescription,
        categories
      }} = item;

      return (
        <Container 
          key={offerId} 
          className={classes.root}
        >
          <Box
            mb={3}
          >
            <Typography 
              variant="h2" 
              component="p" 
              color="textPrimary"
            >
              {offerTitle}
            </Typography>
          </Box>
          <Typography 
            variant="body1" 
            className="description"
          >
            {offerDescription.offerDescription}
          </Typography>
          <Grid 
            container 
            direction="row" 
            wrap="wrap" 
            spacing={5}
          >
          {categories && categories.map((category, index) =>{
            return  (
              <Grid 
                key={category.serviceName + index} 
                item md={4} 
                xl={4} 
                xs={12} 
                sm={6}
              >
                <OfferCard 
                  offerTitle={category.serviceName}
                  offerDescription={category.serviceDescription && category.serviceDescription.serviceDescription}
                  offerImage={category.serviceImage} 
                  offerSlug={`/services/${slug}/${category.serviceSlug}`}
                />
              </Grid>
            );
          })}
          </Grid>  
        </Container>
      );
    })
  )
}

export default WhatWeOffer